import { Role } from '../../common/models/models';

const languages = {
  en: 'Anglais',
  fr: 'Français',
};

const access: {
  roles: Record<Role, string>;
  title: string;
  rolesLabel: string;
  accessLevels: string;
  error: string;
} = {
  title: 'Accès',
  rolesLabel: 'Rôles',
  accessLevels: "Niveaux d'accès",
  error: "Une erreur est survenue. La section sur les accès n'a pas pu être chargée.",
  roles: {
    admin: 'Admin',
    billingClerk: 'Agent de facturation',
    dispatcher: 'Répartiteur',
    salesSupervisor: 'Superviseur des ventes',
    salesRepresentative: 'Représentant aux ventes',
    salesDirector: 'Directeur des ventes',
    salesAdmin: 'Administrateur des ventes',
    salesSuperUser: 'Super utilisateur des ventes',
    salesQuotesExpert: 'Expert en devis de ventes',
    salesCraneChartMaintainer: 'Responsable de la maintenance des tableaux de grues',
    salesReadOnly: 'Ventes lecture seule',
    dispatchReadOnly: 'Répartition lecture seule',
    validationReadOnly: 'Validation lecture seule',
    dispatchChief: 'Chef de répartition',
  },
};

export const userSettings = {
  about: 'À propos',
  language: 'Langue',
  languages,
  settings: 'Paramètres',
  access,
};
