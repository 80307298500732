import { Role } from '../../common/models/models';

const languages = {
  en: 'English',
  fr: 'French',
};

const access: {
  roles: Record<Role, string>;
  title: string;
  rolesLabel: string;
  accessLevels: string;
  error: string;
} = {
  title: 'Access',
  rolesLabel: 'Roles',
  accessLevels: 'Access Levels',
  error: 'An error occured. Could not load access section.',
  roles: {
    admin: 'Admin',
    billingClerk: 'Billing Clerk',
    dispatcher: 'Dispatcher',
    salesSupervisor: 'Sales Supervisor',
    salesRepresentative: 'Sales Representative',
    salesDirector: 'Sales Director',
    salesAdmin: 'Sales Admin',
    salesSuperUser: 'Sales Super User',
    salesQuotesExpert: 'Sales Quotes Expert',
    salesCraneChartMaintainer: 'Sales Crane Chart Maintainer',
    salesReadOnly: 'Sales Read Only',
    dispatchReadOnly: 'Dispatch Read Only',
    validationReadOnly: 'Validation Read Only',
    dispatchChief: 'Dispatch Chief',
  },
};

export const userSettings = {
  about: 'About',
  language: 'Language',
  languages,
  settings: 'Settings',
  access,
};
